export interface LoanStatusSummary {
  approvedCount: number;
  rejectedCount: number;
  pendingCount: number;
  completeCount: number;
}

export interface CustomerLoanStatusSummary {
  fullyPaidCount: number;
  performingCount: number;
  overdueCount: number;
  defaultCount: number;
}

export interface RepaymentData {
  repaymentFrequency: "DAILY" | "WEEKLY" | "MONTHLY";
  count: number;
  totalRemainingAmount: number;
  percentage: number;
}

export interface LoanSummary {
  totalIssuedLoanAmount: number;
  totalInterestAmount: number;
  totalCollectedLoanAmount: number;
  totalCollectedInterest: number;
  remainingLoanAmount: number;
  remainingInterestAmount: number;
  totalAmountNotOverdue: number;
  totalAmountOf2days: number;
  totalAmountOf15days: number;
  totalProfit: number;
  releaseAmount: number;
}

export interface ApplicationOverviewSummary {
  approvedCount: number;
  rejectedCount: number;
  pendingCount: number;
  completeCount: number;
}

export interface CustomerOverviewSummary {
  fullyPaidCount: number;
  performingCount: number;
  overdueCount: number;
  defaultCount: number;
}

export interface Data {
  loanSummary: LoanSummary;
  paymentPlanSummary: RepaymentData[];
  loanStatusSummary: {
    DAILY: LoanStatusSummary;
    WEEKLY: LoanStatusSummary;
    MONTHLY: LoanStatusSummary;
  };
  customerLoanStatusSummary: {
    DAILY: CustomerLoanStatusSummary;
    WEEKLY: CustomerLoanStatusSummary;
    MONTHLY: CustomerLoanStatusSummary;
  };
  applicationOverviewSummary: ApplicationOverviewSummary;
  customerOverviewSummary: CustomerOverviewSummary;
}

// Updated default data based on typical use case
export const defaultData: Data = {
  loanSummary: {
    totalIssuedLoanAmount: 0,
    totalInterestAmount: 0,
    totalCollectedLoanAmount: 0,
    totalCollectedInterest: 0,
    remainingLoanAmount: 0,
    remainingInterestAmount: 0,
    totalAmountNotOverdue: 0,
    totalAmountOf2days: 0,
    totalAmountOf15days: 0,
    totalProfit: 0,
    releaseAmount: 0,
  },
  paymentPlanSummary: [
    {
      repaymentFrequency: "DAILY",
      count: 0,
      totalRemainingAmount: 0,
      percentage: 0,
    },
    {
      repaymentFrequency: "WEEKLY",
      count: 0,
      totalRemainingAmount: 0,
      percentage: 0,
    },
    {
      repaymentFrequency: "MONTHLY",
      count: 0,
      totalRemainingAmount: 0,
      percentage: 0,
    },
  ],
  loanStatusSummary: {
    DAILY: {
      approvedCount: 0,
      rejectedCount: 0,
      pendingCount: 0,
      completeCount: 0,
    },
    WEEKLY: {
      approvedCount: 0,
      rejectedCount: 0,
      pendingCount: 0,
      completeCount: 0,
    },
    MONTHLY: {
      approvedCount: 0,
      rejectedCount: 0,
      pendingCount: 0,
      completeCount: 0,
    },
  },
  customerLoanStatusSummary: {
    DAILY: {
      fullyPaidCount: 0,
      performingCount: 0,
      overdueCount: 0,
      defaultCount: 0,
    },
    WEEKLY: {
      fullyPaidCount: 0,
      performingCount: 0,
      overdueCount: 0,
      defaultCount: 0,
    },
    MONTHLY: {
      fullyPaidCount: 0,
      performingCount: 0,
      overdueCount: 0,
      defaultCount: 0,
    },
  },
  applicationOverviewSummary: {
    approvedCount: 0,
    rejectedCount: 0,
    pendingCount: 0,
    completeCount: 0,
  },
  customerOverviewSummary: {
    fullyPaidCount: 0,
    performingCount: 0,
    overdueCount: 0,
    defaultCount: 0,
  },
};
