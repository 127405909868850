import React, { useRef } from "react";
import { Modal } from "antd";
import { PromissoryNoteFormProps } from "../../../types/formsTypes";
import Button from "../buttons/Button";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const RestructuringLoanAgreementForm: React.FC<PromissoryNoteFormProps> = ({
  visible,
  onClose,
}) => {
  const formRef = useRef<HTMLDivElement>(null);

  const handleDownload = async () => {
    if (formRef.current) {
      const canvas = await html2canvas(formRef.current);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();

      const margin = 10; // Margin in mm
      const pageWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgWidth = pageWidth - 2 * margin;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let position = margin;
      pdf.addImage(imgData, "PNG", margin, position, imgWidth, imgHeight);

      let remainingHeight = imgHeight - (pageHeight - 2 * margin);

      while (remainingHeight > 0) {
        position = margin - (imgHeight - remainingHeight);
        pdf.addPage();
        pdf.addImage(imgData, "PNG", margin, position, imgWidth, imgHeight);
        remainingHeight -= pageHeight - 2 * margin;
      }

      pdf.save("restructuring-loan-agreement.pdf");
    }
  };

  const titleEng =
    "flex items-center justify-center mb-2 font-semibold text-h2 underline decoration-black underline-offset-4";
  const titleSin =
    "flex items-center justify-center mb-2 font-semibold text-h3 underline decoration-black underline-offset-4";

  const title2 = "mt-5 decoration-black underline-offset-2";

  const inputData = "pl-2 w-auto ";

  return (
    <div>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        centered
        style={{ top: 0, padding: 0 }}
        bodyStyle={{
          height: "90vh",
          padding: "1%",
          overflow: "auto",
          scrollbarWidth: "none",
        }}
        width="100vw"
      >
        <div className="h-full w-[1000px] mx-auto">
          {/* download button */}
          <div className="flex justify-center p-2">
            <Button text={"Download"} onClick={handleDownload} />
          </div>

          <div
            ref={formRef}
            className="p-20 mx-auto text-justify "
            style={{ wordSpacing: "7px" }}
          >
            <div>
              <div className={titleEng}>CrediManage (PVT) LTD</div>
              <div className={titleSin}>ක්‍රෙඩිමැනේජ් ප්‍රයිවට් ලිමිටඩ්</div>
              <div
                className={`${title2} text-h5 font-semibold underline text-center`}
              >
                ණය ගිවිසුම නැවත යථාවත් කිරීමේ එකඟතා ගිවිසුමයි
              </div>
            </div>
            <div className={`${title2} text-h5 leading-10`}>
              මෙහි ඇතැම් තැනක ණය හිමි ලෙස හදුන්වනු ලබන
              {/* නම  */}
              <span className={inputData}>hgfgh</span> යන ලිපිනයෙහි ප්‍රධාන
              කාර්යාලය පවත්වගෙන යනු ලබන වර්ෂ 2007 අංක 07 සමාගම් පනත යටතේ යථා
              පරිශි සංස්ථාගත කරනු ලැබ ඇති ක්‍රෙඩිමැනේජ් (ප්‍රයිවට්) ලිමිටඩ් (PV
              00313302) (CrediManage (PVT)LTD) යන සමාගම පළමු පක්ෂය වශයෙන් ද
            </div>

            {/* ණයකරු පිලිබඳ පිලිතුරු  */}
            <div
              className={`${title2} text-h5 leading-10`}
              style={{ wordSpacing: "5px" }}
            >
              මෙහි ඇතැම් තැනක ණයකරු ලෙස හදුන්වනු ලබන
              {/* ලිපිනය  */}
              <span className={inputData}>hgfgh</span> යන ලිපිනයෙහි පදිංචි
              {/* නම  */}
              <span className={inputData}>hgfgh</span>
              (ජාහැ අං-
              {/* හැදුනුම්පත් අංකය  */}
              <span className={inputData}>hgfgh</span>) නම් වන අය සහ
            </div>

            {/* ඇපකරුවන් පිලිබද විස්තර  */}
            <div
              className={`${title2} text-h5 leading-10`}
              style={{ wordSpacing: "5px" }}
            >
              මෙහි පහත ඇතැම් තැනක ඇපකරුවන් ලෙස හදුන්වන ලබන 1
              {/*පළමු ඇපකරු ලිපිනය  */}
              <span className={inputData}>hgfgh</span> ලිපිනයෙහි පදිංචි
              {/* පළමු ඇපකරු නම  */}
              <span className={inputData}>hgfgh</span> (ජාහැ අං-
              {/*පළමු ඇපකරු හැදුනුම්පත් අංකය  */}
              <span className={inputData}>hgfgh</span>) නම් වන අය සහ 2
              {/* දෙවන ඇපකරු ලිපිනය  */}
              <span className={inputData}>hgfgh</span> ලිපිනයෙහි පදිංචි
              {/* දෙවන ඇපකරු නම  */}
              <span className={inputData}>hgfgh</span> (ජාහැ අං-
              {/*දෙවන ඇපකරු හැදුනුම්පත් අංකය  */}
              <span className={inputData}>hgfgh</span>) නම් වන අය දෙවන පක්ෂයටද
              බැදි
            </div>

            <div
              className={`${title2} text-h5 leading-10`}
              style={{ wordSpacing: "5px" }}
            >
              අන්‍යෝන්‍ය එකඟත්වයෙන් යුතුව
              <span className={inputData}>hgfgh</span> දිනැති සහ අංක
              <span className={inputData}>hgfgh</span> දරණ ණය ගිවිසුම පහත
              කොන්දේසි හා නියමයන් ඇතුලුව නැවත යථාවත් කිරීම සදහා එකඟ වන්නාහ.
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RestructuringLoanAgreementForm;
