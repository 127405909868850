import React, { useEffect, useState } from "react";
import { getProfits, getTotalPaidAmounts } from "../../../../api/summarry";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { Button, Description, Title } from "../../../common";

interface profitDataTypes {
  totalInterestPaid: number;
  totalLatePaymentFee: number;
  totalProfit: number;
  totalPureLoanPaid: number;
  releaseAmount: number;
}

const A_Profits: React.FC = () => {
  const [startDate, setStartDate] = useState<string>(
    dayjs().subtract(1, "year").format("YYYY-MM-DDT00:00:00")
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs().format("YYYY-MM-DDTHH:mm:ss")
  );
  const [totalPaidAmount, setTotalPaidAmount] = useState<number>(0);
  const [profitData, setProfitData] = useState<profitDataTypes>({
    totalInterestPaid: 0,
    totalLatePaymentFee: 0,
    totalProfit: 0,
    totalPureLoanPaid: 0,
    releaseAmount: 0,
  });
  const [selectedRange, setSelectedRange] = useState<string>("Year");

  // Computed formatted dates
  const formatedStartDate = dayjs(startDate).format("YYYY-MM-DD");
  const formatedEndDate = dayjs(endDate).format("YYYY-MM-DD");

  // Handle change start date
  const handleStartDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      const updatedDate = newDate.format("YYYY-MM-DDT00:00:00");
      setStartDate(updatedDate);
      setSelectedRange(""); // Remove button selection on manual date change
    } else {
      setStartDate("");
    }
  };

  // Handle change end date
  const handleEndDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      const updatedDate = newDate.format("YYYY-MM-DDT23:59:59");

      setEndDate(updatedDate);
      setSelectedRange("");
    } else {
      setEndDate("");
    }
  };

  const handleButtonClick = (type: "Year" | "Month" | "Week" | "Day") => {
    const parsedEndDate = dayjs(endDate);
    let newStartDate;

    switch (type) {
      case "Year":
        newStartDate = parsedEndDate
          .subtract(1, "year")
          .format("YYYY-MM-DDT00:00:00");
        break;
      case "Month":
        newStartDate = parsedEndDate
          .subtract(1, "month")
          .format("YYYY-MM-DDT00:00:00");
        break;
      case "Week":
        newStartDate = parsedEndDate
          .subtract(1, "week")
          .format("YYYY-MM-DDT00:00:00");
        break;
      case "Day":
        newStartDate = parsedEndDate
          .subtract(1, "day")
          .format("YYYY-MM-DDT00:00:00");
        break;
      default:
        newStartDate = undefined;
    }

    setStartDate(newStartDate || "");
    setEndDate(parsedEndDate.format("YYYY-MM-DDT23:59:59"));
    setSelectedRange(type);
  };

  const getSystemProfits = async () => {
    if (!startDate || !endDate) {
      console.error("Start date and end date must be selected.");
      return;
    }

    const token = localStorage.getItem("token") || "";

    try {
      const totalPaidAmounts = await getTotalPaidAmounts(
        token,
        formatedStartDate,
        formatedEndDate
      );

      if (totalPaidAmounts.success) {
        setTotalPaidAmount(totalPaidAmounts.data.totalPaymentAmount);
      }
    } catch (error) {
      console.error("Error fetching profits:", error);
    }

    try {
      const response = await getProfits(token, startDate, endDate);
      if (response.success) {
        setProfitData(response.data);
      }
    } catch (error) {
      console.error("Error fetching profits:", error);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      getSystemProfits();
    }
  }, [startDate, endDate]);

  const maxStartDate = dayjs(endDate)
    .subtract(1, "day")
    .format("YYYY-MM-DDT00:00:00");

  return (
    <div className="p-4 mb-10 rounded-lg bg-primaryColor-50">
      <div className="justify-center gap-3 sm:grid sm:grid-cols-2 md:flex">
        <div>
          <Description content={"Start Date"} />
          <DatePicker
            value={startDate ? dayjs(startDate) : null}
            onChange={handleStartDateChange}
            maxDate={dayjs(maxStartDate)}
            format="YYYY-MM-DD"
            className="mb-5 w-[100%] md:w-72 py-2 hover:bg-slate-50 focus:outline-none focus-within:ring-0 hover:border-1 hover:border-primaryColor-100 focus-within:border-primaryColor-100"
            popupClassName="z-10"
          />
        </div>
        <div>
          <Description content={"End Date"} />
          <DatePicker
            value={endDate ? dayjs(endDate) : null}
            onChange={handleEndDateChange}
            maxDate={dayjs()}
            format="YYYY-MM-DD"
            className="mb-5 w-[100%] md:w-72 py-2 hover:bg-slate-50 focus:outline-none focus-within:ring-0 hover:border-1 hover:border-primaryColor-100 focus-within:border-primaryColor-100"
            popupClassName="z-10"
          />
        </div>
      </div>

      <div className="flex flex-wrap justify-center gap-3">
        {["Year", "Month", "Week", "Day"].map((type) => (
          <Button
            key={type}
            text={type}
            onClick={() =>
              handleButtonClick(type as "Year" | "Month" | "Week" | "Day")
            }
            border="border-none"
            bgColor={
              selectedRange === type
                ? "bg-white drop-shadow-lg shadow bg-gray-200"
                : "drop-shadow-lg shadow bg-gray-200"
            }
            fontColor="text-black"
            activeBg={false}
          />
        ))}
      </div>

      <div className="flex flex-wrap justify-center gap-10 mt-10">
        <div className="flex flex-col justify-center -space-y-3 text-center">
          <Title
            title={"Total paid amount"}
            bgStyle={false}
            fontWeight="font-normal"
            fontSize=""
          />
          <Title
            title={`Rs. ${totalPaidAmount}`}
            bgStyle={false}
            fontWeight="font-semibold"
          />
        </div>
        <div className="flex flex-col justify-center -space-y-3 text-center">
          <Title
            title={"Total pure loan paid amount"}
            bgStyle={false}
            fontWeight="font-normal"
            fontSize=""
          />
          <Title
            title={`Rs. ${profitData.totalPureLoanPaid}`}
            bgStyle={false}
            fontWeight="font-semibold"
          />
        </div>
        <div className="flex flex-col justify-center -space-y-3 text-center">
          <Title
            title={"Total interest paid amount"}
            bgStyle={false}
            fontWeight="font-normal"
            fontSize=""
          />
          <Title
            title={`Rs. ${profitData.totalInterestPaid}`}
            bgStyle={false}
            fontWeight="font-semibold"
          />
        </div>
        <div className="flex flex-col justify-center -space-y-3 text-center">
          <Title
            title={"Total late fee paid amount"}
            bgStyle={false}
            fontWeight="font-normal"
            fontSize=""
          />
          <Title
            title={`Rs. ${profitData.totalLatePaymentFee}`}
            bgStyle={false}
            fontWeight="font-semibold"
          />
        </div>
        <div className="flex flex-col justify-center -space-y-3 text-center">
          <Title
            title={"Release Amount"}
            bgStyle={false}
            fontWeight="font-normal"
            fontSize=""
          />
          <Title
            title={`Rs. ${profitData.releaseAmount}`}
            bgStyle={false}
            fontWeight="font-semibold"
          />
        </div>
        <div className="flex flex-col justify-center -space-y-3 text-center">
          <Title title={"Total Profit"} bgStyle={false} fontSize="" />
          <Title
            title={`Rs. ${profitData.totalProfit}`}
            bgStyle={false}
            fontWeight="font-semibold"
          />
        </div>
      </div>
    </div>
  );
};

export default A_Profits;
