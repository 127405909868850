import React, { useEffect, useRef, useState } from "react";
import CalendarButton from "../../common/buttons/CalanderButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { getAllshedulesForCalander } from "../../../api/calander";
import { format } from "date-fns";
import CalanderOverviewModal from "../../common/modals/CalanderOverviewModal";
import CalanderShedulesCollectionModal from "../../common/modals/CalanderShedulesCollectionModal";
import { Dayjs } from "dayjs";
import "./calendarStyles.css";
import { Input } from "../../common";
import { FaSearch } from "react-icons/fa";
import { Day, Month, Week, Year } from "../schedule-calander/DayMonthWeekYear";

// Define interfaces for the expected data structures
interface Schedule {
  managerId: number;
  collectorId: number;
  loanId: number;
  customerName: string;
  customerAddress: string;
  customerPhoneNumber: string;
  dueDate: string;
  customerNic: string;
  customerWhatsAppNumber: string;
  numberOfInstallments: number;
  dueAmount: number;
  id?: number;
  loanAmount: number;
  date?: string;
  paymentStatus: string;
}

interface CustomerLoan {
  customerName: string;
  loanId: number;
}

const C_CalenderPage: React.FC = () => {
  const [view, setView] = useState<"Day" | "Week" | "Month" | "Year">("Month");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedJSCalenderDate, setSelectedJSCalenderDate] = useState<Date>(
    new Date()
  );
  const [allSchedules, setAllSchedules] = useState<Schedule[]>([]);
  const [filteredSchedules, setFilteredSchedules] = useState<Schedule[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isCollectionModalOpen, setIsCollectionModalOpen] =
    useState<boolean>(false);
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule | null>(
    null
  );
  const [uniqueCustomerLoans, setUniqueCustomerLoans] = useState<
    CustomerLoan[]
  >([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState<
    CustomerLoan[]
  >([]);
  const [selectedCustomerLoans, setSelectedCustomerLoans] = useState<
    CustomerLoan[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const suggestionsRef = useRef<HTMLDivElement>(null);

  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
  };

  const handleScheduleClick = (schedule: Schedule) => {
    setSelectedSchedule(schedule);
    setIsOpenModal(true);
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
    setSelectedSchedule(null);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchValue(inputValue);

    if (inputValue) {
      const suggestions = uniqueCustomerLoans.filter((loan) =>
        loan.customerName.toLowerCase().startsWith(inputValue.toLowerCase())
      );
      setFilteredSuggestions(suggestions);
    } else {
      setFilteredSuggestions([]);
      setSelectedCustomerLoans([]);
    }
  };

  const handleSelectSuggestion = (suggestion: CustomerLoan) => {
    setSearchValue(suggestion.customerName);

    if (
      !selectedCustomerLoans.some(
        (loan) =>
          loan.customerName === suggestion.customerName &&
          loan.loanId === suggestion.loanId
      )
    ) {
      setSelectedCustomerLoans((prevState) => [...prevState, suggestion]);
    }

    setFilteredSuggestions([]);
  };

  useEffect(() => {
    const fetchSchedules = async () => {
      const token = localStorage.getItem("token") || "";
      const Id = localStorage.getItem("userId") || "";
      const userId = parseInt(Id);

      try {
        const response: Schedule[] = await getAllshedulesForCalander(
          userId,
          token
        );
        setAllSchedules(response);

        const uniqueLoanIds = new Set<number>();
        const uniquePairs = response.reduce<CustomerLoan[]>((acc, schedule) => {
          if (!uniqueLoanIds.has(schedule.loanId)) {
            uniqueLoanIds.add(schedule.loanId);
            acc.push({
              customerName: schedule.customerName,
              loanId: schedule.loanId,
            });
            acc.push({
              customerName: schedule.customerNic,
              loanId: schedule.loanId,
            });
          }
          return acc;
        }, []);

        setUniqueCustomerLoans(uniquePairs);
      } catch (error) {
        console.error("Error fetching schedules:", error);
      }
    };

    fetchSchedules();
  }, []);

  useEffect(() => {
    const dayKey = selectedDate ? format(selectedDate, "yyyy-MM-dd") : null;

    const filteredSchedules = allSchedules.filter((schedule) => {
      const scheduleDateKey = format(new Date(schedule.dueDate), "yyyy-MM-dd");
      const isDateMatch = scheduleDateKey === dayKey;

      if (selectedCustomerLoans.length > 0) {
        return (
          isDateMatch &&
          selectedCustomerLoans.some((loan) => loan.loanId === schedule.loanId)
        );
      }

      return isDateMatch;
    });

    setFilteredSchedules(filteredSchedules);
  }, [selectedDate, allSchedules, selectedCustomerLoans]);

  useEffect(() => {
    if (window.innerWidth < 640) {
      setIsCollectionModalOpen(true);
    }
  }, [selectedDate]);

  const handleCollectionModalClose = () => {
    setIsCollectionModalOpen(false);
  };

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setSelectedJSCalenderDate(newDate.toDate());
    }
  };

  const handleMonthChange = (newMonth: Dayjs) => {
    setSelectedJSCalenderDate(newMonth.toDate());
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target as Node)
    ) {
      setFilteredSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="sm:flex" style={{ height: "90vh" }}>
      {/* Mini calendar */}
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
            slotProps={{
              day: (dayProps) => ({
                sx: {
                  "&:hover": {
                    backgroundColor: dayProps.selected
                      ? "09624d"
                      : "transparent",
                  },
                },
              }),
            }}
          />
        </LocalizationProvider>

        {(view === "Month" || view === "Year") && (
          <div
            className="flex-1 hidden overflow-y-auto -mt-7 sm:block"
            style={{ height: "40vh" }}
          >
            {filteredSchedules.length > 0 && (
              <div className="flex flex-col px-3 pt-1">
                {filteredSchedules.map((schedule, index) => (
                  <div
                    key={index}
                    className="p-4 bg-gray-100 border-b-2 cursor-pointer border-primaryColor-600"
                    onClick={() => handleScheduleClick(schedule)}
                  >
                    <p
                      className={`font-bold ${schedule.paymentStatus === "OVERDUE" ? "text-red-500" : schedule.paymentStatus === "PAID" ? "text-yellow-500" : "text-primaryColor-500"}
                                          `}
                    >
                      {schedule.customerName}
                    </p>
                    <p
                      className={`${schedule.paymentStatus === "OVERDUE" ? "text-red-500" : schedule.paymentStatus === "PAID" ? "text-yellow-500" : "text-primaryColor-500"}`}
                    >
                      {schedule.customerAddress}
                    </p>
                    <p
                      className={`${schedule.paymentStatus === "OVERDUE" ? "text-red-500" : schedule.paymentStatus === "PAID" ? "text-yellow-500" : "text-primaryColor-500"}`}
                    >
                      {schedule.customerPhoneNumber}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col flex-1 bg-white">
        <div className="flex ml-4">
          <div className="relative justify-self-start" ref={suggestionsRef}>
            <Input
              id="filterEmployees"
              name="filterEmployees"
              placeholder="Search"
              value={searchValue}
              onChange={handleSearchChange}
              width="w-[100%] xxs:max-w-60 mt-[10px]"
              frontIcon={<FaSearch />}
            />

            {filteredSuggestions.length > 0 && (
              <ul className="absolute z-50 w-full -mt-4 bg-gray-100">
                {filteredSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelectSuggestion(suggestion)}
                    className="px-1 py-2 rounded-md"
                  >
                    {suggestion.customerName}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full p-2 mx-auto lg:justify-between">
          <div>
            {/* Buttons for selecting calendar view */}
            <CalendarButton
              label="Day"
              currentView={view}
              onClick={() => setView("Day")}
            />
            <CalendarButton
              label="Week"
              currentView={view}
              onClick={() => setView("Week")}
            />
            <CalendarButton
              label="Month"
              currentView={view}
              onClick={() => setView("Month")}
            />
            <CalendarButton
              label="Year"
              currentView={view}
              onClick={() => setView("Year")}
            />
          </div>
        </div>
        {/* Content */}
        <div className="flex-1 overflow-y-auto" style={{ height: "90vh" }}>
          {view === "Day" ? (
            <Day
              selectedDate={selectedJSCalenderDate}
              selectedCustomer={selectedCustomerLoans}
              selectedcollectorId={null}
              selectedManagerId={null}
            />
          ) : view === "Week" ? (
            <Week
              selectedJsCalanderDate={selectedJSCalenderDate}
              selectedCustomer={selectedCustomerLoans}
              selectedcollectorId={null}
              selectedManagerId={null}
            />
          ) : view === "Month" ? (
            <Month
              onDateSelect={handleDateSelect}
              selectedJsCalanderDate={selectedJSCalenderDate}
              selectedCustomer={selectedCustomerLoans}
              selectedcollectorId={null}
              selectedManagerId={null}
            />
          ) : view === "Year" ? (
            <Year
              onDateSelect={handleDateSelect}
              selectedJsCalanderDate={selectedJSCalenderDate}
            />
          ) : null}
        </div>
      </div>

      <CalanderOverviewModal
        visible={isOpenModal}
        onClose={handleModalClose}
        content={selectedSchedule}
        closeIcon
      />

      <CalanderShedulesCollectionModal
        visible={isCollectionModalOpen}
        onClose={handleCollectionModalClose}
        schedules={filteredSchedules}
        selectedDate={selectedDate}
        closeIcon
      />
    </div>
  );
};

export default C_CalenderPage;
