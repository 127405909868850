/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
// @ts-expect-error
import { toDataURL } from "qrcode";
import html2canvas from "html2canvas";
import { Button, Description, Logo } from "../common";
import { FiDownload } from "react-icons/fi";
import toast from "react-hot-toast";

interface QRGenerateProps {
  visible: boolean;
  customerId: string;
  customerName: string;
  customerAddress: string;
  loanStartDate: string;
  loneAmount: string;
  numberOfInstallment: string;
  paymentPlan: string;
  installmentAmount: string;
  imgUrl: string;
  onClose: () => void;
  closeModal?: () => void;
  customerNIC: string;
}

const QrGenerateRelease: React.FC<QRGenerateProps> = ({
  visible,
  customerId,
  customerName,
  customerAddress,
  loanStartDate,
  loneAmount,
  numberOfInstallment,
  paymentPlan,
  installmentAmount,
  imgUrl,
  closeModal,
  customerNIC,
}) => {
  const [qrImg, setQrImg] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false); // Track image load status
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (visible) {
      const qrText = `
        NIC : ${customerNIC},
        Name : ${customerName},
        Address : ${customerAddress},
        Start Date : ${loanStartDate},
        Loan Amount (Rs.) ${loneAmount},
        Number Of Installments : ${numberOfInstallment},
        Payment Plan : ${paymentPlan},
        Installments amount (Rs.) ${installmentAmount},
      `;
      toDataURL(qrText).then((res: string) => setQrImg(res));
    }
  }, [
    visible,
    customerId,
    customerName,
    customerAddress,
    loanStartDate,
    loneAmount,
    numberOfInstallment,
    paymentPlan,
    installmentAmount,
    customerNIC,
  ]);

  const downloadModalImage = async () => {
    if (!imageLoaded) {
      console.warn("Image not loaded yet");
      toast.error("Image not loaded yet");
      return;
    }

    setIsLoading(true);
    if (modalRef.current) {
      try {
        const canvas = await html2canvas(modalRef.current, {
          scale: 3,
          windowWidth: 600,
          windowHeight: 700,
          allowTaint: true,
          useCORS: true,
        });
        const imgData = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.download = `${customerNIC}-QR.png`;
        link.href = imgData;
        link.click();
      } catch (error) {
        console.error("Download failed:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const formattedLoanAmount = new Intl.NumberFormat("en-LK", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(loneAmount));

  const formattedInstallmentAmount = new Intl.NumberFormat("en-LK", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(installmentAmount));

  const getPaymentPlanText = (plan: string): string => {
    switch (plan.toUpperCase()) {
      case "MONTHLY":
        return "මාසික";
      case "DAILY":
        return "දින";
      case "WEEKLY":
        return "සති";
      default:
        return plan;
    }
  };

  return (
    <Modal visible={visible} footer={null} centered={true} closable={false}>
      <div ref={modalRef} className="p-4 px-3">
        <div className="">
          {/* company details  */}
          <div className="">
            <div className="-mb-2 -ml-2">
              <Logo width="w-44 " />
            </div>
            {/* phone number  */}
            <Description
              content="+94742678920, "
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor={"text-gray-400"}
            />
            {/* mail address  */}
            <Description
              content="info@credimanage.com, "
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor={"text-gray-400"}
            />
            {/* address  */}
            <Description
              content="26, ඉසුරු නිවස, "
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor={"text-gray-400"}
            />
            {/* address  */}
            <Description
              content="කෝන්කැටියාර"
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor={"text-gray-400"}
            />
            <Description
              content="සූරියවැව"
              fontSize="text-h8"
              fontWeight="font-bold"
              fontColor={"text-gray-400"}
            />
          </div>

          <div className="border border-gray-100 w-[100%] my-3" />

          <div className="relative grid w-full grid-cols-2 ">
            <div className="flex items-center">
              <div className="w-[170px] h-[150px] bg-cover">
                <img
                  src={imgUrl}
                  alt="Uploaded"
                  className="object-cover w-full h-full rounded-lg"
                  onLoad={() => setImageLoaded(true)}
                />
              </div>
            </div>
            <div className="flex flex-col items-end w-full px-1 text-right">
              <Description
                content={customerName}
                fontWeight="font-bold"
                fontColor="text-black"
              />
              <Description content={customerNIC} fontColor="text-black" right />
              <Description
                content={customerAddress}
                fontColor="text-black"
                right
              />
              <Description
                content={loanStartDate}
                fontColor="text-black"
                right
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center w-full mt-2">
          <div className="grid w-full grid-cols-2 justify-items-center">
            <div className="w-full col-span-1 justify-self-start">
              <div className="col-span-1 justify-self-start">
                <div className="flex items-center justify-between">
                  <Description
                    content={"ගෙවීම් සැලැස්ම"}
                    fontColor="text-black"
                    fontWeight="font-bold"
                  />
                  <div>:</div>
                </div>
              </div>
            </div>
            <div className="self-center col-span-1 justify-self-end">
              <Description
                content={getPaymentPlanText(paymentPlan)}
                fontColor="text-black"
                fontWeight="font-bold"
              />
            </div>
            <div className="w-full col-span-1 justify-self-start">
              <div className="col-span-1 justify-self-start">
                <div className="flex items-center justify-between">
                  <Description
                    content={"වාරික ගණන"}
                    fontColor="text-black"
                    fontWeight="font-bold"
                  />
                  <div>:</div>
                </div>
              </div>
            </div>
            <div className="self-center col-span-1 justify-self-end">
              <Description
                content={numberOfInstallment}
                fontColor="text-black"
              />
            </div>
            <div className="w-full col-span-1 justify-self-start">
              <div className="flex items-center justify-between">
                <Description
                  content={"ණය මුදල (Rs.)"}
                  fontColor="text-black"
                  fontWeight="font-bold"
                />
                <div>:</div>
              </div>
            </div>
            <div className="self-center col-span-1 justify-self-end">
              <Description
                content={formattedLoanAmount}
                fontColor="text-black"
              />
            </div>
            <div className="w-full col-span-1 justify-self-start">
              <div className="col-span-1 justify-self-start">
                <div className="flex items-center justify-between">
                  <Description
                    content={"වාරික මුදල (Rs.)"}
                    fontColor="text-black"
                    fontWeight="font-bold"
                  />
                  <div>:</div>
                </div>
              </div>
            </div>
            <div className="self-center col-span-1 justify-self-end">
              <Description
                content={formattedInstallmentAmount}
                fontColor="text-black"
              />
            </div>
          </div>

          <div className="w-full -mt-4 pb-5 px-2 md:px-7 grid grid-cols-[45%_55%]"></div>
          {qrImg && (
            <img
              src={qrImg}
              alt="qr"
              className="object-contain w-[60%] mt-2 shadow-3xl rounded-xl"
            />
          )}
        </div>
      </div>

      <div className="flex items-center justify-center w-full gap-3 mt-3">
        <Button
          text="Download"
          onClick={downloadModalImage}
          iconPosition="after"
          icon={<FiDownload />}
          isLoading={isLoading}
          disabled={isLoading}
          width="w-36 lg:w-44"
        />
        <Button
          text="Next"
          variant="secondary"
          onClick={closeModal}
          disabled={isLoading}
          width="w-36 lg:w-44"
        />
      </div>
    </Modal>
  );
};

export default QrGenerateRelease;
