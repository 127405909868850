import React, { useState, useEffect } from "react";
import { DatePicker, Modal, message } from "antd";
import { Button, PrimaryTitle } from "../common";
import dayjs, { Dayjs } from "dayjs";
import { changeReleaseDate } from "../../api/loan";

interface ReleaseDateUpdateProps {
  visible: boolean;
  onClose?: () => void;
  closeIcon?: React.ReactNode;
  secondaryOnClick?: () => void;
  loanId: number;
  releasedDate: string;
  onSuccess: (updatedLoanId: number, newReleaseDate: string) => void; // Add this
}

const ReleaseDateUpdate: React.FC<ReleaseDateUpdateProps> = ({
  visible,
  onClose,
  closeIcon,
  secondaryOnClick,
  loanId,
  releasedDate,
  onSuccess,
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs(releasedDate)
  );
  const [isDateChanged, setIsDateChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Reset state when modal opens
    setSelectedDate(dayjs(releasedDate));
    setIsDateChanged(false);
  }, [visible, releasedDate]);

  // Handle date change
  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      const formattedNewDate = newDate.format("YYYY-MM-DD");
      const formattedReleasedDate = dayjs(releasedDate).format("YYYY-MM-DD");

      setSelectedDate(newDate);
      setIsDateChanged(formattedNewDate !== formattedReleasedDate); // Check if the date is actually changed
    }
  };

  // Handle update release date
  const handleUpdateReleaseDate = async () => {
    if (!isDateChanged) {
      message.error("Please select a different date to proceed.");
      return;
    }
    if (!selectedDate || !isDateChanged) return;

    setLoading(true);
    const token = localStorage.getItem("token") || "";

    try {
      // Get the current time (HH:mm:ss) and merge it with the selected date
      const now = dayjs();
      const formattedDate = selectedDate
        .hour(now.hour())
        .minute(now.minute())
        .second(now.second())
        .format("YYYY-MM-DDTHH:mm:ss");

      const response = await changeReleaseDate(token, loanId, formattedDate);

      if (response.success) {
        message.success("Release date updated successfully!");
        onSuccess(loanId, selectedDate.format("YYYY-MM-DD")); // Update table immediately
        onClose?.();
      } else {
        message.error("Failed to update release date. Please try again.");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to update release date. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="custom-modal"
    >
      <div className="p-5">
        <PrimaryTitle
          text="Change Release Date"
          fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
          center
        />

        <div className="flex justify-center mt-5">
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            format="YYYY-MM-DD"
            className="mb-5 w-[100%] xxs:max-w-72 py-2 bg-primaryColor-50 hover:bg-primaryColor-50 focus:outline-none focus-within:ring-0 hover:border-1 hover:border-primaryColor-100 focus-within:border-primaryColor-100 focus-within:bg-primaryColor-50"
            popupClassName="z-10"
            maxDate={dayjs()}
          />
        </div>
        <div className="flex justify-center gap-4 mt-4">
          <Button
            text="Cancel"
            onClick={secondaryOnClick}
            variant="secondary"
            disabled={loading}
          />
          <Button
            text="Done"
            onClick={handleUpdateReleaseDate}
            disabled={!isDateChanged || loading}
            isLoading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ReleaseDateUpdate;
