import React, { useState, useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { LuLayoutDashboard } from "react-icons/lu";
import { FiUserPlus } from "react-icons/fi";
import { MdLogout } from "react-icons/md";
import { CollapsibleButton, CustomButton } from "../../common";
import { useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { GoPeople } from "react-icons/go";
import { MdOutlinePayment } from "react-icons/md";
import { IoAnalyticsOutline } from "react-icons/io5";
import { RiCalendarScheduleLine } from "react-icons/ri";

const M_Sidebar: React.FC = () => {
  const navigate = useNavigate(); // Hook to navigate between routes

  // State to control sidebar open/close status
  const [isOpen, setIsOpen] = useState(false);
  // State to track the active button in the sidebar
  const [activeButton, setActiveButton] = useState<string | null>(null);
  // State to manage the open/close status of collapsible sections
  const [openSection, setOpenSection] = useState<string | null>("Dashboard");
  // Reference to the sidebar element for detecting clicks outside
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  // Effect to set the default active button and open section from localStorage when component mounts
  useEffect(() => {
    const storedActiveButton = localStorage.getItem("activeButton");
    const storedOpenSection = localStorage.getItem("openSection");

    // Set active button from localStorage or default to "Quick actions"
    if (storedActiveButton) {
      setActiveButton(storedActiveButton);
    } else {
      setActiveButton("Quick actions");
      localStorage.setItem("activeButton", "Quick actions");
    }

    // Set open section from localStorage or default to "Dashboard"
    if (storedOpenSection) {
      setOpenSection(storedOpenSection);
    } else {
      setOpenSection("Dashboard");
      localStorage.setItem("openSection", "Dashboard");
    }
  }, []);

  // Effect to handle scrolling on body element
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isOpen]);

  // Function to handle navigation and set active button
  const handleNavigation = (route: string, button: string) => {
    navigate(route);
    setIsOpen(false);
    setActiveButton(button);
    localStorage.setItem("activeButton", button);
  };

  // Function to toggle sidebar open/close status
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle sign out logic
  const handleLogOut = () => {
    // Clear localStorage
    localStorage.clear();

    // Navigate to the home page and clear the history stack
    // Force a page reload to reset the history
    window.location.href = "/";
  };

  // Function to handle clicks on collapsible sections
  const handleCollapsibleClick = (section: string) => {
    const newOpenSection = openSection === section ? null : section;
    setOpenSection(newOpenSection);
    localStorage.setItem("openSection", newOpenSection || "");
  };

  // Effect to handle closing the sidebar when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // handle navigate dashboard
  const navigateAdminDashboard = () => {
    navigate("/dashboard");
    setIsOpen(false);
    setActiveButton("Quick actions");
    localStorage.setItem("activeButton", "Quick actions");
  };

  const navigateAnalytics = () => {
    navigate("/analitics-dashboard");
    setIsOpen(false);
    setActiveButton("Analytics");
    localStorage.setItem("activeButton", "Analytics");
  };

  // handle navigate customer list page
  const navigateCustomerlist = () => {
    handleNavigation("/customer-list", "Customer List");
  };

  const navigateAddCollector = () => {
    handleNavigation("/user-register", "add collector");
  };

  const navigateAddPayment = () => {
    handleNavigation("/add-payment", "Add Payments");
  };

  const navigatePaymentList = () => {
    handleNavigation("/payment-list", "View Payments");
  };

  const navigateGuaranteeList = () => {
    handleNavigation("/guarantee-list", "Guarantee List");
  };
  const navigateBlacklist = () => handleNavigation("/blacklist", "Blacklist");

  const navigateCollateralList = () => {
    handleNavigation("/collateral-list", "Collateral List");
  };

  const navigateLoanList = () => {
    handleNavigation("/loan-list", "Loan List");
  };

  const navigateCollectorList = () => {
    handleNavigation("/employee-list", "Collector List");
  };

  const navigateCalander = () => {
    handleNavigation("/schedule-calender", "Calander");
  };

  const navigateScheduleList = () => {
    handleNavigation("/schedule-list", "Schedule List");
  };
  const navigateApproveRelease = () =>
    handleNavigation("/approve-release", "Approve And Release");

  return (
    <div className="flex">
      {/* Button to toggle sidebar */}
      <button onClick={toggleSidebar} className="p-3 text-h4 md:text-h3 ">
        {isOpen ? <AiOutlineClose /> : <FiMenu />}
      </button>

      {/* Sidebar container */}
      <div
        ref={sidebarRef}
        className={`fixed top-0 w-[100%] xxs:max-w-72 left-0 h-screen bg-primaryColor-50 shadow-3xl transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {/* Sidebar header with title and close button */}
        <div className="flex items-center justify-between p-4">
          <h2 className={`text-xl font-bold ${isOpen ? "" : "hidden"}`}>
            CrediManage
          </h2>
          <button
            onClick={toggleSidebar}
            className="p-1 text-xl duration-300 rounded-lg hover:shadow-inner hover:bg-gray-200"
          >
            <AiOutlineClose className="duration-300 hover:rotate-90 " />
          </button>
        </div>

        {/* Sidebar navigation */}
        <nav className="mt-3 py-3 px-2 no-scrollbar overflow-y-auto h-[calc(100vh-160px)]">
          {/* Dashboard button */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === "Dashboard"}
            onClick={() => handleCollapsibleClick("Dashboard")}
            icon={<LuLayoutDashboard />}
            text={"Dashboard"}
            isActive={activeButton === "Dashboard"}
          >
            <div>
              <CustomButton
                isCollapsed={false}
                text={"Quick actions"}
                isActive={activeButton === "Quick actions"}
                onClick={navigateAdminDashboard}
                icon={undefined}
              />

              <CustomButton
                isCollapsed={false}
                text={"Analytics"}
                icon={undefined}
                isActive={activeButton === "Analytics"}
                onClick={navigateAnalytics}
              />
              <CustomButton
                isCollapsed={false}
                text={"Approve And Release"}
                isActive={activeButton === "Approve And Release"}
                onClick={navigateApproveRelease}
                icon={undefined}
              />
            </div>
          </CollapsibleButton>

          {/* Registration collapsible section */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === "Customers"}
            onClick={() => handleCollapsibleClick("Customers")}
            icon={<FiUserPlus />}
            text={"Customers"}
            isActive={activeButton === "Customer List"}
          >
            <div>
              <CustomButton
                isCollapsed={false}
                text={"Customer Registration"}
                isActive={activeButton === "Customer Registration"}
                onClick={() =>
                  handleNavigation(
                    "/customer-register",
                    "Customer Registration"
                  )
                }
                icon={undefined}
              />
              <CustomButton
                isCollapsed={false}
                text={"Customer List"}
                icon={undefined}
                isActive={activeButton === "Customer List"}
                onClick={navigateCustomerlist}
              />
            </div>
          </CollapsibleButton>

          {/* Payments collapsible section */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === "Payments"}
            onClick={() => handleCollapsibleClick("Payments")}
            icon={<MdOutlinePayment />}
            text={"Payments"}
            isActive={
              activeButton === "Add Payments" ||
              activeButton === "View Payments"
            }
          >
            <div>
              <CustomButton
                isCollapsed={false}
                text={"Add Payments"}
                icon={undefined}
                isActive={activeButton === "Add Payments"}
                onClick={navigateAddPayment}
              />
              <CustomButton
                isCollapsed={false}
                text={"View Payments"}
                icon={undefined}
                isActive={activeButton === "View Payments"}
                onClick={navigatePaymentList}
              />
            </div>
          </CollapsibleButton>

          {/* Analytics button */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === "analytics"}
            onClick={() => handleCollapsibleClick("analytics")}
            icon={<IoAnalyticsOutline />}
            text={"Analytics"}
            isActive={activeButton === "analytics"}
          >
            <div>
              <CustomButton
                isCollapsed={false}
                text={"Guarantee List"}
                icon={undefined}
                isActive={activeButton === "Guarantee List"}
                onClick={navigateGuaranteeList}
              />
              <CustomButton
                isCollapsed={false}
                text={"Collateral List"}
                icon={undefined}
                isActive={activeButton === "Collateral List"}
                onClick={navigateCollateralList}
              />
              <CustomButton
                isCollapsed={false}
                text={"Loan List"}
                icon={undefined}
                isActive={activeButton === "Loan List"}
                onClick={navigateLoanList}
              />
              <CustomButton
                isCollapsed={false}
                text={"Blacklist"}
                icon={undefined}
                isActive={activeButton === "Blacklist"}
                onClick={navigateBlacklist}
              />
            </div>
          </CollapsibleButton>

          {/* Schedules section */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === "Schedules"}
            onClick={() => handleCollapsibleClick("Schedules")}
            icon={<RiCalendarScheduleLine />}
            text={"Schedules"}
            isActive={activeButton === "Schedules"}
          >
            <div>
              <CustomButton
                isCollapsed={false}
                text={"Schedule List"}
                icon={undefined}
                isActive={activeButton === "Schedule List"}
                onClick={navigateScheduleList}
              />
              <CustomButton
                isCollapsed={false}
                text={"Calender"}
                icon={undefined}
                isActive={activeButton === "Calander"}
                onClick={navigateCalander}
              />
            </div>
          </CollapsibleButton>

          {/* Collectors collapsible section */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === "collectors"}
            onClick={() => handleCollapsibleClick("collectors")}
            icon={<GoPeople />}
            text={"Collectors"}
            isActive={activeButton === "collectors"}
          >
            <div>
              <CustomButton
                isCollapsed={false}
                text={"Add Collector"}
                icon={undefined}
                isActive={activeButton === "add collector"}
                onClick={navigateAddCollector}
              />
              <CustomButton
                isCollapsed={false}
                text={"Collector List"}
                icon={undefined}
                isActive={activeButton === "Collector List"}
                onClick={navigateCollectorList}
              />
            </div>
          </CollapsibleButton>

          {/* Sign out button */}
          <CustomButton
            isCollapsed={false}
            text="Sign Out"
            icon={<MdLogout className="text-lg" />}
            isActive={activeButton === "Log Out"}
            isSignOut={false}
            onClick={handleLogOut}
            fontWeight
          />
        </nav>
      </div>
    </div>
  );
};

export default M_Sidebar;
