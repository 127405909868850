import React, { useEffect, useState } from "react";
import { Empty, Form, Table } from "antd";
import { getPaymentSchedulesBycusID } from "../../../api/schedule";

interface Payment {
  paymentId: string;
  customerName: string;
  collectorName: string;
  paymentDate: string;
  dueAmount: number;
  paidAmount: number;
  balanceAmount: number;
  paymentType: string;
}

interface Schedule {
  scheduleId: number;
  dueDate: string;
  currentInstallmentAmount: number;
  paymentStatus: string;
  paymentType: string;
}

interface Item extends Payment, Schedule {}

const M_LastpaymentsSchedulesTable = ({
  customerId,
}: {
  customerId: number;
  getCustomerPaymentsDetails?: () => void;
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<Item[]>([]);
  const [filter, setFilter] = useState<string>("PAID"); // State for filter

  const columns = [
    {
      title: "Installment (Rs.)",
      dataIndex: "dueAmount",
      width: 150,
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      width: 150,
      render: (text: string, record: Item) => {
        let colorClass = "";
        if (record.paymentType === "EARLY") {
          colorClass = "text-blue-500";
        } else if (record.paymentType === "LATE") {
          colorClass = "text-red-500";
        } else {
          colorClass = "text-primaryColor-500";
        }
        return (
          <span className={colorClass}>
            {new Date(text).toLocaleDateString()}
          </span>
        );
      },
      align: "center" as const,
    },
    {
      title: "Paid Amount (Rs.)",
      dataIndex: "paidAmount",
      width: 150,
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      align: "center" as const,
    },
  ];

  const getPaymentDetails = async () => {
    const token = localStorage.getItem("token") || "";

    try {
      const response = await getPaymentSchedulesBycusID(customerId, token);

      const combinedData: Item[] = response.schedules.map(
        (schedule: {
          scheduleId: number;
          dueDate: string;
          currentInstallmentAmount: number;
          paymentStatus: string;
          paymentType: string;
        }) => ({
          scheduleId: schedule.scheduleId,
          paymentDate: schedule.dueDate,
          dueAmount: schedule.currentInstallmentAmount,
          paidAmount: 0,
          paymentStatus: schedule.paymentStatus,
          paymentType: schedule.paymentType,
        })
      );

      if (response.payments) {
        const paymentsData = response.payments.map(
          (payment: {
            paymentId: number;
            customerName: string;
            collectorName: string;
            paymentDate: string;
            paidAmount: number;
            balanceAmount: number;
            paymentType: string;
          }) => ({
            paymentId: payment.paymentId,
            customerName: payment.customerName,
            collectorName: payment.collectorName,
            paymentDate: payment.paymentDate,
            dueAmount: 0,
            paidAmount: payment.paidAmount,
            balanceAmount: payment.balanceAmount,
            paymentStatus: "PAID",
            paymentType: payment.paymentType,
          })
        );

        combinedData.push(...paymentsData);
      }

      combinedData.sort((a) => (a.paymentStatus === "PAID" ? -1 : 1));

      setData(combinedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (customerId) {
      getPaymentDetails();
    }
  }, [customerId]);

  // Filtered data based on selected status
  const filteredData = data
    .filter((item) => item.paymentStatus === filter)
    .reverse();

  return (
    <div className="">
      <div>
        <div className="flex justify-end gap-3 py-1 text-primaryColor-900 text-h9">
          <div className="flex items-center gap-1 font-semibold ">
            <div className="w-3 h-3 rounded bg-primaryColor-500" />
            <div>Schedule</div>
          </div>
          <div className="flex items-center gap-1 font-semibold ">
            <div className="w-3 h-3 bg-blue-500 rounded" />
            <div>Early</div>
          </div>
          <div className="flex items-center gap-1 font-semibold ">
            <div className="w-3 h-3 bg-red-500 rounded" />
            <div>Late</div>
          </div>
        </div>
      </div>
      <div className="ml-2 font-semibold text-h8">
        <button
          className={`px-2 ${filter === "PAID" ? "bg-gray-200" : ""}`}
          onClick={() => setFilter("PAID")}
        >
          PAID
        </button>
        <button
          className={`px-2 ${filter === "PENDING" ? "bg-gray-200  " : ""}`}
          onClick={() => setFilter("PENDING")}
        >
          PENDING
        </button>
        <button
          className={`px-2 ${filter === "OVERDUE" ? "bg-gray-200  " : ""}`}
          onClick={() => setFilter("OVERDUE")}
        >
          OVERDUE
        </button>
      </div>
      <Form form={form} component={false}>
        <Table
          scroll={{ x: "max-content", y: 240 }}
          bordered
          dataSource={filteredData}
          columns={columns}
          className="font-semibold h-80"
          locale={{
            emptyText: <Empty description={<div>No data available</div>} />,
          }}
        />
      </Form>
    </div>
  );
};

export default M_LastpaymentsSchedulesTable;
