import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import {
  Button,
  DatePicker,
  Empty,
  Form,
  Modal,
  Table,
  Typography,
} from "antd";
import {
  ButtonPrimary,
  Description,
  Dropdown,
  Input,
  Title,
} from "../../common";
import { getAllPayments, updatePaidAmount } from "../../../api/payment";
import { PaymentReciptPaymentList } from "../../modal";
import toast from "react-hot-toast";
import dayjs, { Dayjs } from "dayjs";
import { formatDate } from "../../../utils/formatters";

interface Item {
  signatureImageURL: string;
  customerId: number;
  id: number;
  paymentId: string;
  customerName: string;
  collectorName: string;
  paymentDate: string;
  paymentType: string;
  dueAmount: number;
  paidAmount: number;
  balanceAmount: number;
  remainingAmount: number;
  nic: string;
  address: string;
  repaymentFrequency: string;
  loanAmount: number;
  releaseDate: string;
  totalPaymentAmount: number;
  totalLatePaymentInterest: number;
  interestAmount: number;
  timeout: boolean;
  scheduleId: number;
  scheduleInterestAmount: number;
  remainingOverdueAmount: number;
}

// Define the type for receipt details
type ReceiptDetails = {
  customer: string;
  nic: string;
  paymentDate: string;
  paymentTime: string;
  loanAmount: number;
  paidAmount: number;
  remainingAmount: number;
  paymentType: string;
  repaymentFrequency: string;
  releaseDate: string;
  customerId: number;
  address: string;
  fullLoanAmount: number;
  lateInstallmentAmount: number;
  totlaIntrestAmount: number;
  remainingOverdueAmount: number;
  // Add other necessary details here
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        ></Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const A_PaymentListTable = () => {
  const [form] = Form.useForm();
  const [customersData, setCustomersData] = useState<Item[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [showAllTotals] = useState<boolean>(true);
  const [selectedPaymentStatus, setSelectedPaymentStatus] =
    useState<string>("All");

  // State to handle payment receipt modal visibility and details
  const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<Item | null>(null);
  const [receiptDetails, setReceiptDetails] = useState<ReceiptDetails>({
    customer: "",
    nic: "",
    paymentDate: "",
    paymentTime: "",
    loanAmount: 0,
    paidAmount: 0,
    remainingAmount: 0,
    paymentType: "",
    repaymentFrequency: "",
    releaseDate: "",
    customerId: 0,
    address: "",
    fullLoanAmount: 0,
    lateInstallmentAmount: 0,
    totlaIntrestAmount: 0,
    remainingOverdueAmount: 0,
  });
  const [isSignatureModalVisible, setIsSignatureModalVisible] = useState(false);
  const [signatureImageURL, setSignatureImageURL] = useState<string | null>(
    null
  );
  const [isOpenEditPaidAmount, setIsOpenPaidAmount] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<Dayjs>();

  // Function to handle opening the modal
  const handleOpenSignatureModal = (url: string) => {
    setSignatureImageURL(url);
    setIsSignatureModalVisible(true);
  };

  // Function to handle closing the modal
  const handleCloseSignatureModal = () => {
    setIsSignatureModalVisible(false);
    setSignatureImageURL(null); // Clear the image URL when modal closes
  };

  // handle close payment receipt modal
  const handleClosePaymentReceiptModal = () => {
    setIsReceiptModalVisible(false);
  };

  // handle change paid amount
  const handleOpenEditPaidAmount = (record: Item) => {
    setSelectedPayment(record);
    setIsOpenPaidAmount(true);
    setSelectedDate(record.paymentDate ? dayjs(record.paymentDate) : dayjs());
  };
  const handleCloseEditPaidAmount = () => {
    setIsOpenPaidAmount(false);
    setIsSaveEnabled(false);
    form.resetFields();
    setErrorMessage("");
  };

  const columns = [
    {
      title: "Payment ID",
      dataIndex: "paymentId",
      fixed: "left" as const,
      align: "center" as const,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      align: "center" as const,
    },
    {
      title: "Payment Receiver Name",
      dataIndex: "collectorName",
      align: "center" as const,
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      render: (text: string) => new Date(text).toLocaleDateString(),
      align: "center" as const,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      align: "center" as const,
      render: (text: string) => {
        let color = "";
        if (text === "EARLY") {
          color = "blue";
        } else if (text === "SCHEDULE") {
          color = "green";
        } else if (text === "LATE") {
          color = "red";
        }
        return (
          <span style={{ color, fontWeight: "bold" }}>{text || "N/A"}</span>
        );
      },
    },
    {
      title: "Due Amount (Rs.)",
      dataIndex: "dueAmount",
      render: (amount: number) =>
        `${amount != null ? amount.toFixed(2) : "0.00"}`,
      align: "center" as const,
    },
    {
      title: "Paid Amount (Rs.)",
      dataIndex: "paidAmount",
      render: (amount: number) =>
        `${amount != null ? amount.toFixed(2) : "0.00"}`,
      align: "center" as const,
    },
    {
      title: "Edit Paid Amount",
      dataIndex: "signatureImageURL",
      align: "center" as const,
      render: (_text: string, record: Item) => (
        <div className="flex justify-center font-semibold">
          <Button
            type="link"
            onClick={() => handleOpenEditPaidAmount(record)}
            className="font-bold"
            disabled={record.timeout}
          >
            Edit
          </Button>
        </div>
      ),
    },
    {
      title: "Balance Amount (Rs.)",
      dataIndex: "balanceAmount",
      render: (amount: number) =>
        `${amount != null ? amount.toFixed(2) : "0.00"}`,
      align: "center" as const,
    },
    {
      title: "Customer Sign",
      dataIndex: "signatureImageURL",
      align: "center" as const,
      render: (signatureImageURL: string) => (
        <div className="flex justify-center font-semibold">
          {signatureImageURL ? (
            <Button
              type="link"
              onClick={() => handleOpenSignatureModal(signatureImageURL)}
            >
              View
            </Button>
          ) : (
            <span className="opacity-50">No Signature</span>
          )}
        </div>
      ),
    },
    {
      title: "View",
      dataIndex: "paymentId",
      render: (_paymentId: string, record: Item) => (
        <div className="flex justify-center">
          <Typography.Link
            onClick={() => {
              setSelectedPayment(record); // Set the selected payment details
              setIsReceiptModalVisible(true); // Open the modal

              // Set receipt details when a payment is selected
              setReceiptDetails({
                customer: record.customerName || "",
                nic: record.nic, // Populate as needed
                paymentDate:
                  new Date(record.paymentDate).toLocaleDateString() || "",
                paymentTime:
                  new Date(record.paymentDate).toLocaleTimeString() || "",
                loanAmount: record.loanAmount, // Populate as needed
                paidAmount: record.paidAmount != null ? record.paidAmount : 0,
                remainingAmount:
                  record.balanceAmount != null ? record.balanceAmount : 0,
                paymentType: record.paymentType || "",
                repaymentFrequency: record.repaymentFrequency, // Populate as needed
                releaseDate: record.releaseDate
                  ? new Date(record.releaseDate).toLocaleDateString()
                  : "", // Format date only
                customerId: record.customerId, // Populate as needed
                address: record.address,
                fullLoanAmount: record.totalPaymentAmount,
                lateInstallmentAmount: record.totalLatePaymentInterest,
                totlaIntrestAmount: record.interestAmount,
                remainingOverdueAmount: record.remainingOverdueAmount || 0,
              });
            }}
          >
            <div className="flex justify-center w-20 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900">
              View
            </div>
          </Typography.Link>
        </div>
      ),
      align: "center" as const,
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "netValue" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  // Fetch all payments data
  const getPaymentsData = async () => {
    const token = localStorage.getItem("token") || "";
    const appUserId = Number(localStorage.getItem("userId")) || 0;

    try {
      const response = await getAllPayments(token, appUserId);

      // Sort the data by paymentId
      const sortedData = response.sort((a: Item, b: Item) => {
        const paymentIdA = parseInt(a.paymentId, 10);
        const paymentIdB = parseInt(b.paymentId, 10);
        return paymentIdA - paymentIdB;
      });

      setCustomersData(sortedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPaymentsData();
  }, []);

  // handle edit paid amount
  const handleEditPaidAmount = async () => {
    if (!selectedPayment) return;

    const token = localStorage.getItem("token") || "";
    const paymentId = Number(selectedPayment.paymentId);
    const updatedPaidAmount = form.getFieldValue("editPaidAmount");

    const currentTime = new Date();
    const hours = String(currentTime.getHours()).padStart(2, "0");
    const minutes = String(currentTime.getMinutes()).padStart(2, "0");
    const seconds = String(currentTime.getSeconds()).padStart(2, "0");

    const currentTimeString = `${hours}:${minutes}:${seconds}`;
    const selectedDateString = selectedDate
      ? selectedDate.format("YYYY-MM-DD")
      : null;

    const combinedDateTime = selectedDateString
      ? `${selectedDateString}T${currentTimeString}`
      : null;

    const requestBody = {
      customerId: selectedPayment.customerId,
      scheduleId: selectedPayment.scheduleId,
      paidAmount: updatedPaidAmount,
      paymentType: selectedPayment.paymentType,
      appUserId: Number(localStorage.getItem("userId")) || 0,
      signatureImageURL: selectedPayment.signatureImageURL || "",
      date: combinedDateTime,
    };

    try {
      const response = await updatePaidAmount(token, paymentId, requestBody);
      console.log("response : ", response);
      if (response.success) {
        toast.success(response.message);
        getPaymentsData();
        handleCloseEditPaidAmount();
      } else {
        toast.error(response.response.data.message);
      }
      setIsOpenPaidAmount(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Function to handle date changes, keeping selectedDate as a Dayjs object
  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      const minDate = selectedPayment
        ? dayjs(formatDate(selectedPayment.releaseDate))
        : undefined;

      if (minDate && newDate.isBefore(minDate)) {
        setErrorMessage(`Date cannot be earlier than the release date.`);
        setIsSaveEnabled(false);
      } else if (newDate.isAfter(dayjs())) {
        setErrorMessage(`Date cannot be in the future.`);
        setIsSaveEnabled(false);
      } else {
        setSelectedDate(newDate); // Update state with valid date
        setErrorMessage(""); // Clear error if valid
        setIsSaveEnabled(true); // Enable save button
      }
    }
  };

  // Filter data based on search text and payment status, and then reverse the order
  const filteredData = customersData
    .filter((item) => {
      const searchValue = searchText.toLowerCase();
      const matchesSearchText =
        item.customerName.toLowerCase().includes(searchValue) ||
        item.collectorName.toLowerCase().includes(searchValue);
      const matchesPaymentStatus =
        selectedPaymentStatus === "All" ||
        item.paymentType === selectedPaymentStatus;

      return matchesSearchText && matchesPaymentStatus;
    })
    .reverse(); // Reverse the filtered data

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  // Calculate totals for all data
  const totalPaidAmount = customersData.reduce(
    (sum, item) => sum + (item.paidAmount || 0),
    0
  );

  // Calculate totals for filtered data
  const filteredTotalPaidAmount = filteredData.reduce(
    (sum, item) => sum + (item.paidAmount || 0),
    0
  );

  const paymentStatus = ["All", "EARLY", "SCHEDULE", "LATE"];

  // Footer component for totals
  const footer = () => {
    const paidAmount = showAllTotals
      ? totalPaidAmount
      : filteredTotalPaidAmount;

    return (
      <>
        <div className="space-y-1">
          {/* Paid Amount total */}
          <div className="xxs:flex w-[100%] max-w-96 justify-between">
            <div className="xxs:w-44">
              <Description content="Paid Amount Total" fontWeight="font-bold" />
            </div>
            <Description content={`Rs. ${paidAmount.toFixed(2)}`} />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="justify-between gap-2 xxs:flex">
        <Dropdown
          label="Payment Status"
          fontSize="text-h9"
          fontColor={"text-gray-400"}
          options={paymentStatus}
          selectedOption={selectedPaymentStatus}
          onOptionSelect={(option: string) => setSelectedPaymentStatus(option)}
          placeholder="Collateral Status"
          width="w-[100%] xxs:max-w-60"
        />
        <div className="xxs:mt-[18px]">
          <Input
            id={"filterPayments"}
            name={"filterPayments"}
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            width="w-[100%] xxs:max-w-60 -mt-[2px]"
          />
        </div>
      </div>

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData}
          columns={mergedColumns}
          rowClassName="editable-row"
          className="font-semibold"
          footer={footer}
          scroll={{ x: "max-content" }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>No Payments Found</span>}
              />
            ),
          }}
        />
      </Form>

      {/* Payment Receipt Modal */}
      {selectedPayment && (
        <PaymentReciptPaymentList
          visible={isReceiptModalVisible}
          onClose={handleClosePaymentReceiptModal}
          receiptDetails={receiptDetails}
          primaryBtnLabel="Download Receipt"
          primaryOnClick={() => {
            // Logic to download receipt as a PDF
            const element = document.createElement("a");
            const file = new Blob([JSON.stringify(receiptDetails, null, 2)], {
              type: "application/json",
            });
            element.href = URL.createObjectURL(file);
            element.download = "receipt.json"; // Change this to "receipt.pdf" if you generate a PDF
            document.body.appendChild(element);
            element.click();
          }}
        />
      )}

      {/* signature modal  */}
      <Modal
        open={isSignatureModalVisible}
        onCancel={handleCloseSignatureModal}
        footer={null}
        width={300}
        centered
      >
        {signatureImageURL && (
          <img
            src={signatureImageURL}
            alt="Customer Signature"
            className="pt-5 mx-auto max-w-56"
          />
        )}
      </Modal>

      {/* edit paid amount moda  */}
      <Modal
        open={isOpenEditPaidAmount}
        onCancel={handleCloseEditPaidAmount}
        footer={null}
        centered
      >
        <div className="py-10">
          <Title
            title={"Edit Paid Amount"}
            bgStyle={false}
            className="text-center"
          />
          <Form
            form={form}
            initialValues={{ editPaidAmount: selectedPayment?.paidAmount }}
            onValuesChange={(changedValues) => {
              const updatedPaidAmount = form.getFieldValue("editPaidAmount");
              const updatedDate = selectedDate;

              // Validate Paid Amount
              if (
                updatedPaidAmount >
                (selectedPayment?.remainingAmount || 0) +
                  (selectedPayment?.paidAmount || 0)
              ) {
                setErrorMessage(
                  `Paid amount cannot exceed remaining amount: Rs. ${
                    (selectedPayment?.remainingAmount || 0) +
                    (selectedPayment?.paidAmount || 0)
                  }`
                );
                setIsSaveEnabled(false);
              } else if (
                updatedPaidAmount <
                (selectedPayment?.scheduleInterestAmount || 0)
              ) {
                setErrorMessage(
                  `Paid amount cannot be less than the interest amount: Rs. ${selectedPayment?.scheduleInterestAmount.toFixed(
                    2
                  )}`
                );
                setIsSaveEnabled(false);
              } else if (!updatedDate) {
                setErrorMessage(`Please select a valid date.`);
                setIsSaveEnabled(false);
              } else {
                setErrorMessage(""); // Clear error messages when inputs are valid
                setIsSaveEnabled(true); // Enable save button
              }
            }}
          >
            <DatePicker
              value={selectedDate}
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              className="mb-5 w-[100%] xxs:max-w-72 py-2 bg-primaryColor-50 hover:bg-primaryColor-50 focus:outline-none focus-within:ring-0 hover:border-1 hover:border-primaryColor-100 focus-within:border-primaryColor-100 focus-within:bg-primaryColor-50"
              popupClassName="z-10"
              maxDate={dayjs()}
              minDate={
                selectedPayment
                  ? dayjs(formatDate(selectedPayment.releaseDate))
                  : undefined
              }
            />
            <Form.Item
              name="editPaidAmount"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input
                id={"editPaidAmount"}
                name={"editPaidAmount"}
                placeholder={"Paid Amount"}
                type="number"
                minValue="0"
                errorMessage={errorMessage}
              />
            </Form.Item>
          </Form>

          <div className="flex justify-center gap-3 -mt-4">
            <ButtonPrimary
              text={"Save"}
              onClick={handleEditPaidAmount}
              width="w-24"
              disabled={!isSaveEnabled}
            />
            <ButtonPrimary
              text={"Cancel"}
              variant="secondary"
              onClick={handleCloseEditPaidAmount}
              width="w-24"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default A_PaymentListTable;
