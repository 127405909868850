import React, { useRef } from "react";
import { Modal } from "antd";
import { PromissoryNoteFormProps } from "../../../types/formsTypes";
import Button from "../buttons/Button";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const PromissoryNoteForm: React.FC<PromissoryNoteFormProps> = ({
  visible,
  onClose,
}) => {
  const formRef = useRef<HTMLDivElement>(null);

  const handleDownload = async () => {
    if (formRef.current) {
      const canvas = await html2canvas(formRef.current);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let position = 0;
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);

      if (imgHeight > pageHeight) {
        while (position + imgHeight > pageHeight) {
          position -= pageHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        }
      }

      pdf.save("promissory_note_form.pdf");
    }
  };

  const inputData =
    "border-b-[2.5px] border-black border-dotted px-3 font-semibold ";

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      centered
      style={{ top: 0, padding: 0 }}
      bodyStyle={{ height: "90vh", padding: "1%" }}
      width="100vw"
    >
      <div className="w-full h-full overflow-auto">
        {/* download button */}
        <div className="flex justify-center p-2">
          <Button text={"Download"} onClick={handleDownload} />
        </div>

        {/* form */}
        <div
          ref={formRef}
          className="border border-gray-200 w-[1000px] relative mx-auto px-10 grid grid-cols-8 py-20 rounded-xl"
        >
          {/* first column */}
          <main className="col-span-2 pr-5 mt-10 space-y-10 leading-8 text-h7">
            <div>
              <div>01 ණය මූදල - </div>
              <div className={inputData}>Rs. 50000.00</div>
            </div>
            <div>
              <div>02 මූදල් ගැනීමේදී අඩු කරන ලද පොලිය - </div>
              <div className={inputData}>Rs. 50000.00</div>
            </div>
            <div>
              <div>03 පොලිය </div>
              <div>(වාර්ෂික/මාසික) </div>
              <div className={inputData}>Rs. 50000.00</div>
            </div>
            <div>
              <div>04 ණය ගැනුම්කරුවන්ගේ සංක්ෂේප අත්සන </div>
              <div className={inputData}>Chalana</div>
            </div>
            <div>
              <span>දිනය </span>
              <span className={`${inputData} pb-1`}>2025-02-03</span>
            </div>
          </main>

          {/* second column */}
          <main className="col-span-6 px-5 border-l-[3px] border-black text-h7">
            <div className="flex items-center justify-center font-semibold border-[3px] w-[400px] h-16 rounded-xl border-neutral-950 text-h4">
              පොරොන්දු නෝට්ටුවයි
            </div>
            <div className="flex flex-wrap gap-2 mt-7">
              වර්ෂ 202
              <div className="border-b-[2.5px] border-black border-dotted px-3 font-semibold w-20 text-center">
                5
              </div>
              ක් වූ
              <div className="border-b-[2.5px] border-black border-dotted px-3 pb-1 font-semibold w-40 text-center">
                පෙබරවාරි
              </div>
              මස
            </div>
            <div className="flex flex-wrap">
              <div className={`${inputData} pb-1 w-40 text-center`}>02</div>{" "}
              දිනදීය.
            </div>
            {/* image  */}
            <div>
              <img
                src="/images/star-without-bg.png"
                alt="star-without-bg"
                className="absolute top-0 right-0 w-64"
              />
            </div>
          </main>
        </div>
      </div>
    </Modal>
  );
};

export default PromissoryNoteForm;
