import React, { useRef } from "react";
import { Modal } from "antd";
import html2canvas from "html2canvas";
import { Amounts, Button, Description, Logo } from "../common";

const PaymentReciptPaymentList: React.FC<{
  visible: boolean;
  onClose?: () => void;
  primaryBtnLabel?: string;
  primaryOnClick?: () => void;
  closeIcon?: React.ReactNode;
  receiptDetails: {
    customer: string;
    nic: string;
    paymentDate: string;
    paymentTime: string;
    loanAmount: number;
    paidAmount: number;
    remainingAmount: number;
    paymentType: string;
    repaymentFrequency: string;
    releaseDate: string;
    customerId: number;
    address: string;
    fullLoanAmount: number;
    lateInstallmentAmount: number;
    totlaIntrestAmount: number;
    remainingOverdueAmount: number;
  };
}> = ({
  visible,
  onClose,
  primaryBtnLabel = "Download Receipt",
  closeIcon,
  receiptDetails,
}) => {
  const receiptRef = useRef<HTMLDivElement>(null);

  const handleDownloadReceipt = async () => {
    if (receiptRef.current) {
      const canvas = await html2canvas(receiptRef.current);
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `${receiptDetails.nic}_receipt.png`;
      link.click();
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="custom-modal-payment-recipt"
    >
      <div className="bg-primaryColor-50">
        <div ref={receiptRef} className="mt-6 bg-white receipt">
          <div className="p-5 bg-gray-200" />
          <div className="p-5">
            <div className="flex justify-between">
              {/* customer details  */}
              <div>
                <Description
                  content={"ගණුදෙනුකරු"}
                  fontSize="text-h7"
                  fontWeight="font-bold"
                />
                <div className="mt-2">
                  {/* name  */}
                  <Description
                    content={receiptDetails.customer}
                    fontSize="text-h8"
                    fontWeight="font-bold"
                    fontColor={"text-gray-400"}
                  />
                  {/* nic  */}
                  <Description
                    content={receiptDetails.nic}
                    fontSize="text-h8"
                    fontWeight="font-bold"
                    fontColor={"text-gray-400"}
                  />
                  {/* address  */}
                  <Description
                    content={receiptDetails.address}
                    fontSize="text-h8"
                    fontWeight="font-bold"
                    fontColor={"text-gray-400"}
                  />
                </div>
              </div>

              {/* our details    */}
              <div>
                <div className="flex justify-end -mt-3 -ml-2">
                  <Logo width="w-40" />
                </div>
                {/* phone number  */}
                <Description
                  content="+94742678920, "
                  fontSize="text-h8"
                  fontWeight="font-bold"
                  fontColor={"text-gray-400"}
                  right
                />
                {/* mail address  */}
                <Description
                  content="info@credimanage.com, "
                  fontSize="text-h8"
                  fontWeight="font-bold"
                  fontColor={"text-gray-400"}
                  right
                />
                {/* address  */}
                <Description
                  content="26, ඉසුරු නිවස, "
                  fontSize="text-h8"
                  fontWeight="font-bold"
                  fontColor={"text-gray-400"}
                  right
                />
                {/* address  */}
                <Description
                  content="කෝන්කැටියාර"
                  fontSize="text-h8"
                  fontWeight="font-bold"
                  fontColor={"text-gray-400"}
                  right
                />
                <Description
                  content="සූරියවැව"
                  fontSize="text-h8"
                  fontWeight="font-bold"
                  fontColor={"text-gray-400"}
                  right
                />
              </div>
            </div>
            {/* horizontal line  */}
            <hr className="mt-4 mb-3 border " />
            <div className="space-y-1">
              {/* paid amount  */}
              <div className="flex justify-between">
                <Description
                  content="ගෙවීම් සැලැස්ම :"
                  fontSize="text-h8"
                  fontWeight="font-bold"
                  fontColor={"text-gray-500"}
                />
                <Description
                  content={
                    receiptDetails.repaymentFrequency === "MONTHLY"
                      ? "මාස"
                      : receiptDetails.repaymentFrequency === "WEEKLY"
                        ? "සති"
                        : "දවස්"
                  }
                  fontSize="text-h8"
                  fontColor={"text-gray-500"}
                />
              </div>
              {/* Issue Date */}
              <div className="flex justify-between">
                <Description
                  content="නිකුත් කරන දිනය :"
                  fontSize="text-h8"
                  fontWeight="font-bold"
                  fontColor={"text-gray-500"}
                />
                <Description
                  content={receiptDetails.releaseDate}
                  fontSize="text-h8"
                  fontColor={"text-gray-500"}
                />
              </div>
              {/* loan amount  */}
              <div className="flex justify-between">
                <Description
                  content="ණය මුදල (රු.) :"
                  fontSize="text-h8"
                  fontWeight="font-bold"
                  fontColor={"text-gray-500"}
                />
                <Amounts
                  content={receiptDetails.loanAmount}
                  fontSize="text-h8"
                  fontColor={"text-gray-500"}
                />
              </div>
              {/* total interest amount  */}
              <div className={`flex justify-between  `}>
                <Description
                  content="මුළු පොලී මුදල (රු.) :"
                  fontSize="text-h8"
                  fontWeight="font-bold"
                  fontColor={"text-gray-500"}
                />
                <Amounts
                  content={receiptDetails.totlaIntrestAmount}
                  fontSize="text-h8"
                  fontColor={"text-gray-500"}
                />
              </div>
              {/* late Installment Amount    */}
              <div
                className={`flex justify-between ${
                  receiptDetails.remainingOverdueAmount <= 0 ? "hidden" : ""
                }`}
              >
                <Description
                  content="ප්‍රමාද වාරික මුදල (රු.) :"
                  fontSize="text-h8"
                  fontWeight="font-bold"
                  fontColor={"text-gray-500"}
                />
                <Amounts
                  content={Number(
                    receiptDetails.remainingOverdueAmount.toFixed(2)
                  )}
                  fontSize="text-h8"
                  fontColor={"text-gray-500"}
                />
              </div>
              {/* balance carried forword  */}
              <div
                className={`flex justify-between ${
                  receiptDetails.remainingOverdueAmount >= 0 ? "hidden" : ""
                }`}
              >
                <Description
                  content="ඉදිරියට ගෙන ගිය ශේෂය (රු.) :"
                  fontSize="text-h8"
                  fontWeight="font-bold"
                  fontColor={"text-gray-500"}
                />
                <Amounts
                  content={Number(
                    Math.abs(receiptDetails.remainingOverdueAmount).toFixed(2)
                  )}
                  fontSize="text-h8"
                  fontColor={"text-gray-500"}
                />
              </div>
              {/* full loan amount    */}
              <div className="flex justify-between">
                <Description
                  content="මුළු ණය මුදල (රු.) :"
                  fontSize="text-h8"
                  fontWeight="font-bold"
                />
                <Amounts
                  content={receiptDetails.fullLoanAmount}
                  fontSize="text-h8"
                  fontWeight="font-bold"
                />
              </div>
              {/* paid amount  */}
              <div className="flex justify-between">
                <Description
                  content="ඉතිරි මුදල :"
                  fontSize="text-h8"
                  fontWeight="font-bold"
                  fontColor={"text-gray-500"}
                />
                <Amounts
                  content={receiptDetails.remainingAmount}
                  fontSize="text-h8"
                  fontColor={"text-gray-500"}
                />
              </div>
            </div>
            {/* details  */}
            <div className="mt-6 space-y-3">
              {/* customer id  */}
              <div className="w-28">
                <Description
                  content="Customer ID"
                  fontSize="text-h9"
                  fontColor={"text-gray-400"}
                  center
                />
                <div className="flex justify-center">
                  <span className="font-semibold text-h6">#00</span>
                  <Amounts
                    content={receiptDetails.customerId}
                    fontSize="text-h6"
                    format={false}
                  />
                </div>
              </div>
              <div className="flex justify-between">
                {/* paid amount */}
                <div className="w-28">
                  <Description
                    content="ගෙවූ මුදල (රු.)"
                    fontSize="text-h9"
                    fontWeight="font-bold"
                    fontColor={"text-gray-400"}
                    center
                  />
                  <div className="flex justify-center">
                    <Amounts
                      content={receiptDetails.paidAmount}
                      fontSize="text-h6"
                    />
                  </div>
                </div>

                {/* transaction status */}
                <div className="w-32">
                  <Description
                    content="ගනුදෙනු තත්ත්වය"
                    fontSize="text-h9"
                    fontWeight="font-bold"
                    fontColor={"text-gray-400"}
                    center
                  />
                  <div className="flex justify-center">
                    <Description content={"සාර්ථකයි"} fontSize="text-h6" />
                  </div>
                </div>
              </div>

              <div className="flex justify-between">
                {/* Transaction date */}
                <div className="w-28">
                  <Description
                    content="ගනුදෙනු දිනය"
                    fontSize="text-h9"
                    fontWeight="font-bold"
                    fontColor={"text-gray-400"}
                    center
                  />
                  <div className="flex justify-center">
                    <Description
                      content={receiptDetails.paymentDate}
                      fontSize="text-h6"
                    />
                  </div>
                </div>
                {/* Transaction time */}
                <div className="w-28">
                  <Description
                    content="ගනුදෙනු කාලය"
                    fontSize="text-h9"
                    fontWeight="font-bold"
                    fontColor={"text-gray-400"}
                    center
                  />
                  <div className="flex justify-center">
                    <Description
                      content={receiptDetails.paymentTime}
                      fontSize="text-h6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-5 bg-gray-200" />
        </div>
        <div className="flex justify-center mt-4">
          <Button text={primaryBtnLabel} onClick={handleDownloadReceipt} />
        </div>
      </div>
    </Modal>
  );
};

export default PaymentReciptPaymentList;
