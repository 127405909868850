import React, { useState, useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { LuLayoutDashboard } from "react-icons/lu";
import { FiUserPlus } from "react-icons/fi";
import { MdLogout } from "react-icons/md";
import { CollapsibleButton, CustomButton } from "../../common";
import { useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { MdOutlinePayment } from "react-icons/md";
import { IoAnalyticsOutline } from "react-icons/io5";
import { RiCalendarScheduleLine } from "react-icons/ri";

const C_Sidebar: React.FC = () => {
  const navigate = useNavigate(); // Hook to navigate between routes

  // State to control sidebar open/close status
  const [isOpen, setIsOpen] = useState(false);
  // State to track the active button in the sidebar
  const [activeButton, setActiveButton] = useState<string | null>(null);
  // State to manage the open/close status of collapsible sections
  const [openSection, setOpenSection] = useState<string | null>(null);
  // Reference to the sidebar element for detecting clicks outside
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  // Effect to set the default active button and section from localStorage when component mounts
  useEffect(() => {
    const storedActiveButton = localStorage.getItem("activeButton");
    const storedOpenSection = localStorage.getItem("openSection");

    if (storedActiveButton) {
      setActiveButton(storedActiveButton);
    } else {
      setActiveButton("Quick actions"); // Default active button
      localStorage.setItem("activeButton", "Quick actions");
    }

    if (storedOpenSection) {
      setOpenSection(storedOpenSection);
    } else {
      setOpenSection("Dashboard"); // Default open section
      localStorage.setItem("openSection", "Dashboard");
    }
  }, []);

  // Effect to handle scrolling on body element
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isOpen]);

  // Function to handle navigation and set active button
  const handleNavigation = (route: string, button: string) => {
    navigate(route);
    setIsOpen(false);
    setActiveButton(button);
    localStorage.setItem("activeButton", button); // Store active button in localStorage
  };

  // Function to toggle sidebar open/close status
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle sign out logic
  const handleLogOut = () => {
    // Clear localStorage
    localStorage.clear();

    // Navigate to the home page and clear the history stack
    // Force a page reload to reset the history
    window.location.href = "/";
  };

  // Function to handle clicks on collapsible sections
  const handleCollapsibleClick = (section: string) => {
    const newOpenSection = openSection === section ? null : section;
    setOpenSection(newOpenSection);
    localStorage.setItem("openSection", newOpenSection || ""); // Store open section in localStorage
  };

  // Effect to handle closing the sidebar when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle navigate dashboard
  const navigateCollectorDashboard = () => {
    navigate("/dashboard");
    setIsOpen(false);
    setActiveButton("Quick actions");
    localStorage.setItem("activeButton", "Quick actions");
  };

  const navigateAnalytics = () => {
    navigate("/analitics-dashboard");
    setIsOpen(false);
    setActiveButton("Analytics");
    localStorage.setItem("activeButton", "Analytics");
  };

  const navigateCalendar = () => {
    navigate("/schedule-calender");
    setIsOpen(false);
    setActiveButton("Calander");
    localStorage.setItem("activeButton", "Calander");
  };

  const navigateScheduleList = () => {
    navigate("/schedule-list");
    setIsOpen(false);
    setActiveButton("Schedule List");
    localStorage.setItem("activeButton", "Schedule List");
  };

  return (
    <div className="flex">
      {/* Button to toggle sidebar */}
      <button onClick={toggleSidebar} className="p-3 text-h4 md:text-h3 ">
        {isOpen ? <AiOutlineClose /> : <FiMenu />}
      </button>

      {/* Sidebar container */}
      <div
        ref={sidebarRef}
        className={`fixed top-0 w-[100%] xxs:max-w-72 left-0 h-screen bg-primaryColor-50 shadow-3xl transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {/* Sidebar header with title and close button */}
        <div className="flex items-center justify-between p-4">
          <h2 className={`text-xl font-bold ${isOpen ? "" : "hidden"}`}>
            CrediManage
          </h2>
          <button
            onClick={toggleSidebar}
            className="p-1 text-xl duration-300 rounded-lg hover:shadow-inner hover:bg-gray-200"
          >
            <AiOutlineClose className="duration-300 hover:rotate-90 " />
          </button>
        </div>

        {/* Sidebar navigation */}
        <nav className="mt-3 py-3 px-2 no-scrollbar overflow-y-auto h-[calc(100vh-160px)]">
          {/* Dashboard button */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === "Dashboard"}
            onClick={() => handleCollapsibleClick("Dashboard")}
            icon={<LuLayoutDashboard />}
            text={"Dashboard"}
            isActive={activeButton === "Dashboard"}
          >
            <div>
              <CustomButton
                isCollapsed={false}
                text={"Quick actions"}
                isActive={activeButton === "Quick actions"}
                onClick={navigateCollectorDashboard}
                icon={undefined}
              />

              <CustomButton
                isCollapsed={false}
                text={"Analytics"}
                icon={undefined}
                isActive={activeButton === "Analytics"}
                onClick={navigateAnalytics}
              />
            </div>
          </CollapsibleButton>

          {/* Registration collapsible section */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === "Customers"}
            onClick={() => handleCollapsibleClick("Customers")}
            icon={<FiUserPlus />}
            text={"Customers"}
            isActive={activeButton === "Customers"}
          >
            <div>
              <CustomButton
                isCollapsed={false}
                text={"Customer Registration"}
                isActive={activeButton === "Customer Registration"}
                onClick={() =>
                  handleNavigation(
                    "/customer-register",
                    "Customer Registration"
                  )
                }
                icon={undefined}
              />

              <CustomButton
                isCollapsed={false}
                text={"Customer List"}
                icon={undefined}
                isActive={activeButton === "Customer List"}
                onClick={() =>
                  handleNavigation("/customer-list", "Customer List")
                }
              />
            </div>
          </CollapsibleButton>

          {/* Payments collapsible section */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === "Payments"}
            onClick={() => handleCollapsibleClick("Payments")}
            icon={<MdOutlinePayment />}
            text={"Payments"}
            isActive={activeButton === "Payments"}
          >
            <div>
              <CustomButton
                isCollapsed={false}
                text={"Add Payments"}
                icon={undefined}
                isActive={activeButton === "Add Payments"}
                onClick={() => handleNavigation("/add-payment", "Add Payments")}
              />
              <CustomButton
                isCollapsed={false}
                text={"View Payments"}
                icon={undefined}
                isActive={activeButton === "View Payments"}
                onClick={() =>
                  handleNavigation("/payment-list", "View Payments")
                }
              />
            </div>
          </CollapsibleButton>

          {/* Analytics button */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === "analytics"}
            onClick={() => handleCollapsibleClick("analytics")}
            icon={<IoAnalyticsOutline />}
            text={"Analytics"}
            isActive={activeButton === "analytics"}
          >
            <div>
              <CustomButton
                isCollapsed={false}
                text={"Guarantee List"}
                icon={undefined}
                isActive={activeButton === "Guarantee List"}
                onClick={() =>
                  handleNavigation("/guarantee-list", "Guarantee List")
                }
              />
              <CustomButton
                isCollapsed={false}
                text={"Collateral List"}
                icon={undefined}
                isActive={activeButton === "Collateral List"}
                onClick={() =>
                  handleNavigation("/collateral-list", "Collateral List")
                }
              />
              <CustomButton
                isCollapsed={false}
                text={"Loan List"}
                icon={undefined}
                isActive={activeButton === "Loan List"}
                onClick={() => handleNavigation("/loan-list", "Loan List")}
              />
            </div>
          </CollapsibleButton>

          {/* Schedules section */}
          <CollapsibleButton
            isCollapsed={false}
            isOpen={openSection === "Schedules"}
            onClick={() => handleCollapsibleClick("Schedules")}
            icon={<RiCalendarScheduleLine />}
            text={"Schedules"}
            isActive={activeButton === "Schedules"}
          >
            <div>
              <CustomButton
                isCollapsed={false}
                text={"Schedule List"}
                icon={undefined}
                isActive={activeButton === "Schedule List"}
                onClick={navigateScheduleList}
              />
              <CustomButton
                isCollapsed={false}
                text={"Calander"}
                icon={undefined}
                isActive={activeButton === "Calander"}
                onClick={navigateCalendar}
              />
            </div>
          </CollapsibleButton>

          {/* Sign out button */}
          <CustomButton
            isCollapsed={false}
            text="Sign Out"
            icon={<MdLogout className="text-lg" />}
            isActive={activeButton === "Log Out"}
            isSignOut={false}
            onClick={handleLogOut}
            fontWeight
          />
        </nav>
      </div>
    </div>
  );
};

export default C_Sidebar;
