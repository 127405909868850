import axios from "axios";
import { apiOptionJson, BASE_URL } from "./base-url";

interface LoanDuration {
  id: number;
  day: number;
  month: number;
  week: number;
}

interface dataProps {
  userId: number;
  reason: string;
}

interface postLoanDataProps {
  rateId: number;
  name: string;
  rate: number;
}

// get all loans
export const getAllLoans = async (userId: number, token: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/loans/allLoans/${userId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get loan details
export const getLoanDetail = async (loanId: number, token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/loans/${loanId}`, {
      headers: {
        ...apiOptionJson.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// loan approved by loan id and manager id
export const approvedByLoanIdManagerId = async (
  loanId: number,
  managerId: number,
  token: string
) => {
  try {
    const reponse = await axios.post(
      `${BASE_URL}/api/v1/loans/approve/${loanId}/${managerId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return reponse.data;
  } catch (error) {
    return error;
  }
};
// Loan summery by manager id
export const loanSummeryManagerId = async (
  managerId: number,
  token: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/loan-summary/get-manager-summary/${managerId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
// Loan summery by collector id
export const loanSummeryCollectorId = async (
  collectorId: number,
  token: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/loan-summary/get-collector-summary/${collectorId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // Accessing the `data` object from the response
    return response.data.data; // This extracts the `data` object from the API response
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Loan summery by user id
export const loanSummeryUserId = async (token: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/loan-summary/get-admin-summary`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
// Transactions today
export const fetchLoanAndPaymentsToday = async (
  token: string,
  userId: number,
  frequency: "DAILY"
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/loan-summary/total-loan-and-payments-today/${userId}/${frequency}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching today's loan and payments data:", error);
    throw error;
  }
};
// Transactions 7 days
export const fetchLoanAndPaymentsPast7Days = async (
  token: string,
  userId: number,
  frequency: "DAILY"
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/loan-summary/total-loan-and-payments-past-7-days/${userId}/${frequency}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching loan and payments data for the past 7 days:",
      error
    );
    throw error;
  }
};
// Transactions  30 days

export const fetchLoanAndPaymentsPast30Days = async (
  token: string,
  userId: number,
  frequency: "DAILY"
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v1/loan-summary/total-loan-and-payments-past-30-days/${userId}/${frequency}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching loan and payments data:", error);
    throw error;
  }
};

// Loan repay by admin id
export const loanRepayAdminId = async (adminId: number, token: string) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/loan-summary/repayment-count-admin/${adminId}`,
      {},
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// release funds
export const relaseFunds = async (
  loanId: number,
  managerId: number,
  token: string,
  employeeSignatureImgUrl: string,
  customerSignatureImgUrl: string,
  password: string,
  releaseDate: string
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/loans/release_funds?loanId=${loanId}&managerId=${managerId}`,
      {
        employeeSignatureImgUrl,
        customerSignatureImgUrl,
        password,
        releaseDate,
      },
      {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
// loan approved by loan id and manager id
export const approvedLoanByManagerId = async (
  loanId: number,
  managerId: number,
  token: string
) => {
  try {
    const reponse = await axios.get(
      `${BASE_URL}/api/v1/loans/approve/${loanId}/${managerId}`,

      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return reponse.data;
  } catch (error) {
    return error;
  }
};

// loan reject
export const rejectLoan = async (
  loanId: number,
  data: dataProps,
  token: string
) => {
  try {
    const reponse = await axios.post(
      `${BASE_URL}/api/v1/loans/${loanId}/reject`,
      data,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return reponse.data;
  } catch (error) {
    return error;
  }
};

// loan approved by manager with assign collector
export const approvedLoanByManagerWithAssignCollector = async (
  loanId: number,
  managerId: number,
  collectorId: number,
  token: string
) => {
  try {
    const reponse = await axios.get(
      `${BASE_URL}/api/v1/loans/approve/${loanId}/${managerId}?collectorId=${collectorId}`,

      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return reponse.data;
  } catch (error) {
    return error;
  }
};

// loan approved by loan id and admin id
export const approvedLoanByAdminId = async (
  loanId: number,
  token: string,
  userId: number
) => {
  try {
    const reponse = await axios.get(
      `${BASE_URL}/api/v1/loans/approveLoanByAdmin/${loanId}/${userId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return reponse.data;
  } catch (error) {
    return error;
  }
};

interface ApproveLoanBody {
  managerId: number;
  collectorId?: number;
}

// loan approved with assign manager
export const approvedLoanWithAssignManager = async (
  loanId: number,
  data: ApproveLoanBody,
  token: string,
  userId: number
) => {
  try {
    const reponse = await axios.post(
      `${BASE_URL}/api/v1/loans/assignManager/${loanId}?userId=${userId}`,
      data,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return reponse.data;
  } catch (error) {
    return error;
  }
};

// loan approved with assign manager
export const approvedLoanWithAssignCollector = async (
  loanId: number,
  data: ApproveLoanBody,
  token: string,
  userId: number
) => {
  try {
    const reponse = await axios.post(
      `${BASE_URL}/api/v1/loans/assignCollector/${loanId}?userId=${userId}`,
      data,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return reponse.data;
  } catch (error) {
    return error;
  }
};

// get loan duration
export const getLoanDurations = async (token: string) => {
  try {
    const reponse = await axios.get(
      `${BASE_URL}/api/v8/loanDurations`,

      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return reponse.data;
  } catch (error) {
    return error;
  }
};

// post loan duration
export const postLoanDurations = async (data: LoanDuration, token: string) => {
  try {
    const reponse = await axios.put(
      `${BASE_URL}/api/v8/loanDurations/1`,
      data,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return reponse.data;
  } catch (error) {
    return error;
  }
};

// get loan rate
export const getLoanrate = async (token: string) => {
  try {
    const reponse = await axios.get(
      `${BASE_URL}/api/v1/interest`,

      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return reponse.data;
  } catch (error) {
    return error;
  }
};

// post loan rate
export const postLoanRate = async (data: postLoanDataProps, token: string) => {
  try {
    const reponse = await axios.post(`${BASE_URL}/api/v1/interest`, data, {
      headers: {
        ...apiOptionJson.headers,
        Authorization: `Bearer ${token}`,
      },
    });

    return reponse.data;
  } catch (error) {
    return error;
  }
};

// get interest rates
export const getIntrestRates = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/regular-rates`, {
      headers: {
        ...apiOptionJson.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// update interest rate
export const updateIntrestRate = async (token: string, newRate: number) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/api/v1/interest/regular-rate?newRate=${newRate}`,
      {},
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// add interest rate
export const addInterestRate = async (token: string, rate: number) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/regular-rates`,
      { rate },
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// delete interest rate
export const deleteinterestRate = async (token: string, rateId: number) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/api/regular-rates/${rateId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get assigned manager and collector
export const getAssignedManagerCollector = async (
  token: string,
  customerId: number
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v4/customers/userData/${customerId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// change release date
export const changeReleaseDate = async (
  token: string,
  loanId: number,
  releasedDate: string
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/api/v1/loans/update-release-date/${loanId}/${releasedDate}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...apiOptionJson.headers,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
