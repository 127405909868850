import React, { useEffect, useState } from "react";
import { AnaliticsCard } from "../../../common/";
import { FaWallet } from "react-icons/fa";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { loanSummeryUserId } from "../../../../api/loan";
import { Data, defaultData } from "../DefaultData";

const A_AnaliticsCardsSection = () => {
  const [data, setData] = useState<Data>(defaultData);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token") || "";
      try {
        const fetchedData = await loanSummeryUserId(token);

        setData(fetchedData.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-wrap justify-center gap-4 mb-6">
      <AnaliticsCard
        title="Disbursed Loan Amount"
        amount={data.loanSummary.totalIssuedLoanAmount.toFixed(2)}
        icon={<FaMoneyBillTransfer />}
        amountFontColor="text-black"
      />
      <AnaliticsCard
        title="Total Recovered Amount"
        amount={data.loanSummary.totalCollectedLoanAmount.toFixed(2)}
        icon={<FaWallet />}
        amountFontColor="text-black"
      />
      <AnaliticsCard
        title="Total Profit"
        amount={data.loanSummary.totalProfit.toFixed(2)}
        icon={<FaMoneyBillTransfer />}
        amountFontColor="text-black"
      />
      <AnaliticsCard
        title="Total Amount Due"
        amount={data.loanSummary.remainingLoanAmount.toFixed(2)}
        icon={<FaMoneyBillTransfer />}
        amountFontColor="text-soft-red"
      />
      <AnaliticsCard
        title="Late Amount (2 days)"
        amount={data.loanSummary.totalAmountOf2days.toFixed(2)}
        icon={<FaMoneyBillTransfer />}
        amountFontColor="text-black"
      />
      <AnaliticsCard
        title="Late Amount (15 days)"
        amount={data.loanSummary.totalAmountOf15days.toFixed(2)}
        icon={<FaWallet />}
        amountFontColor="text-black"
      />
      <AnaliticsCard
        title="Release Amount"
        amount={String(data.loanSummary.releaseAmount)}
        icon={<FaWallet />}
        amountFontColor="text-black"
      />
      <AnaliticsCard
        title="Amount Due Normally"
        amount={data.loanSummary.totalAmountNotOverdue.toFixed(2)}
        icon={<FaMoneyBillTransfer />}
        amountFontColor="text-black"
      />
    </div>
  );
};

export default A_AnaliticsCardsSection;
