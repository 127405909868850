import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import {
  CustomerRegister,
  Dashboard,
  Login,
  UserRegister,
  UserOverview,
  CustomerList,
  EmployeeList,
  CustomerRegisterOverview,
  AddPayment,
  PaymentList,
  ConsoleLog,
  GuaranteeList,
  CollateralList,
  LoanList,
  ScheduleList,
  AnaliticsDasboard,
  ToBeApproveCusOverview,
  ReleaseFunds,
  CustomerUpdateOverview,
  SystemSettings,
  AssignEmployee,
  CalenderShedules,
  ApproveRelease,
} from "../pages";
import RouteWatcher from "./RouteWatcher";
import Blacklist from "../pages/Blacklist";
import { Modal } from "antd";
import { getCurrentUser } from "../api/auth";
import { Button, Description, PrimaryTitle } from "../components/common";
import { Loading } from "../components/page";

const MainRouter: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    const checkUserAuthentication = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setIsAuthenticated(false);
        setLoading(false);
        return;
      }

      try {
        const user = await getCurrentUser(token);
        if (user.success) {
          setIsAuthenticated(true);
        } else {
          setSessionExpired(true);
        }
      } catch {
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkUserAuthentication();
  }, []);

  // handle navigate login screen
  const navigateLogin = () => {
    setSessionExpired(false);
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <RouteWatcher>
        <Routes>
          {/* Default route for login */}
          {/* If token is valid, redirect to Dashboard; otherwise, show Login */}
          <Route
            path="/"
            element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
          />
          {/* Route with MainLayout as the default layout */}
          <Route element={<MainLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="user-register" element={<UserRegister />} />
            <Route path="customer-register" element={<CustomerRegister />} />
            <Route
              path="customer-register-overview"
              element={<CustomerRegisterOverview />}
            />
            <Route path="user-overview/:userId" element={<UserOverview />} />
            <Route path="customer-list" element={<CustomerList />} />
            <Route path="employee-list" element={<EmployeeList />} />
            <Route path="add-payment" element={<AddPayment />} />
            <Route path="payment-list" element={<PaymentList />} />
            <Route path="console-log" element={<ConsoleLog />} />
            <Route path="guarantee-list" element={<GuaranteeList />} />
            <Route path="collateral-list" element={<CollateralList />} />
            <Route path="loan-list" element={<LoanList />} />
            <Route path="schedule-list" element={<ScheduleList />} />
            <Route path="analitics-dashboard" element={<AnaliticsDasboard />} />
            <Route path="release-funds" element={<ReleaseFunds />} />
            <Route
              path="approve-overview/:userId"
              element={<ToBeApproveCusOverview />}
            />
            <Route
              path="customer-update-overview/:userId"
              element={<CustomerUpdateOverview />}
            />
            <Route path="system-settings" element={<SystemSettings />} />
            <Route path="schedule-calender" element={<CalenderShedules />} />
            <Route path="assign-employee" element={<AssignEmployee />} />
            <Route path="blacklist" element={<Blacklist />} />
            <Route path="approve-release" element={<ApproveRelease />} />
          </Route>
        </Routes>
      </RouteWatcher>

      {/* Session Expired Modal */}
      <Modal open={sessionExpired} centered footer={null} closable={false}>
        <div className="space-y-5">
          <PrimaryTitle
            text={"Session Expired"}
            fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
            center
          />
          <Description
            content={"Your session has expired. Please log in again."}
            center
          />

          <div className="flex justify-center">
            <Button text={"Sign In"} width="min-w-40" onClick={navigateLogin} />
          </div>
        </div>
      </Modal>
    </BrowserRouter>
  );
};

export default MainRouter;
